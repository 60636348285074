import React from "react"
import ReactDynamicImport from "react-dynamic-import"
import { detectMob } from "@src/Helper"
import SEO from "@components/seo"

const loader = () => {
  if (!detectMob()) {
    return import("@components/Tools/Tools")
  } else {
    return import("@components/Tools/ToolsMobile")
  }
}

const Tools = ReactDynamicImport({ loader })

const ToolsPage = ({location}) => {
  return (
    <>
      <SEO title="Tools" />
      <Tools location={location}/>
    </>
  )
}

export default ToolsPage
